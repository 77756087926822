import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";

const DeleteCaretaker = () => {
  const navigate = useNavigate();
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    if (confirmation) {
      // Retrieve the selected caretaker id from local storage
      const selectedCaretakerId = localStorage.getItem("selectedCaretakerId");

      // Retrieve the propertyId from local storage
      const propertyId = localStorage.getItem("selectedPropertyId");
      const token = localStorage.getItem("token");

      // Construct the delete URL
      const deleteURL = `https://backend.inyumba.co.ke/iNyumba/users/caretaker/${propertyId}/${selectedCaretakerId}/delete`;

      // Send DELETE request to the backend API
      axios
        .delete(deleteURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          // Once deletion is successful, clear the selected caretaker id from local storage
          localStorage.removeItem("selectedCaretakerId");

          toast.success("Caretaker deletion is successful!");

          // Navigate back to the caretakers page
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error deleting caretaker:", error);
          toast.error("Error deleting caretaker");
        });
    }
  }, [confirmation, navigate]);

  const handleDelete = () => {
    setConfirmation(true);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <AuthGuard>
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full bg-white p-6 rounded-md shadow-md">
          <h1 className="text-2xl font-semibold mb-4">Delete Caretaker</h1>
          <p className="mb-4">
            Are you sure you want to delete this caretaker?
          </p>
          <div className="flex justify-end">
            <button
              onClick={handleCancel}
              className="mr-2 bg-gray-300 text-gray-800 py-2 px-4 rounded hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
            >
              No
            </button>
            <button
              onClick={handleDelete}
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 focus:outline-none focus:bg-red-600"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </AuthGuard>
  );
};

export default DeleteCaretaker;
