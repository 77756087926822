import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";

const AmenitiesPage = () => {
  const [amenities, setAmenities] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const propertyId = localStorage.getItem("selectedPropertyId");
    const token = localStorage.getItem("token");

    if (propertyId && token) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/amenities/fetch/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setAmenities(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching amenities:", error);
        });
    }
  }, []);

  const handleAddAmenity = () => {
    setShowAddModal(true);
  };

  const closeModal = () => {
    setShowAddModal(false);
  };

  const addAmenity = () => {
    const propertyId = localStorage.getItem("selectedPropertyId");
    const token = localStorage.getItem("token");
    const amenityName = document.getElementById("amenityName").value;

    if (!propertyId || !token || !amenityName) {
      console.error("Property ID, token, or amenity name not found.");
      return;
    }

    const newAmenityData = {
      amenity_name: amenityName,
    };

    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/amenities/post/${propertyId}`,
        newAmenityData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Amenity added successfully!");
        closeModal();
        // Optionally, update amenities list
        setAmenities([...amenities, response.data]);
      })
      .catch((error) => {
        console.error("Error adding amenity:", error);
      });
  };

  const handleUpdateAmenity = (amenity) => {
    localStorage.setItem("selectedAmenityId", amenity.amenity_id);
    navigate("/landlord/updateamenity");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Amenities</h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {amenities.map((amenity) => (
            <div key={amenity.id} className="bg-white shadow-md rounded-md p-4">
              <p className="font-semibold mb-2">
                Amenity Name: {amenity.amenity_name}
              </p>
              <button
                onClick={() => handleUpdateAmenity(amenity)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
              >
                Update or Delete
              </button>
            </div>
          ))}
        </div>

        <button
          onClick={handleAddAmenity}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-8"
        >
          Add Amenity
        </button>

        <Modal
          isOpen={showAddModal}
          onRequestClose={closeModal}
          className="modal flex items-center justify-center"
        >
          <div className="bg-white w-1/3 p-6 rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">Add Amenity</h2>
            <div className="mb-4">
              <label
                htmlFor="amenityName"
                className="block text-sm font-semibold"
              >
                Amenity Name:
              </label>
              <input
                type="text"
                id="amenityName"
                className="w-full border rounded-md py-2 px-3"
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={addAmenity}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Add
              </button>
              <button
                onClick={closeModal}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </AuthGuard>
  );
};

export default AmenitiesPage;
