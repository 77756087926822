import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";

const PropertyKYCPage = () => {
  const [propertyKycData, setPropertyKycData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch propertyId from local storage
    const propertyId = localStorage.getItem("selectedPropertyId");
    if (!propertyId) {
      console.error("Property ID not found in local storage");
      return;
    }

    // Fetch property KYC data using propertyId
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/propertykyc/fetch/${propertyId}`,
        config
      )
      .then((response) => {
        setPropertyKycData(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching property KYC data:", error);
      });
  }, []);

  const handleAddPropertyKyc = () => {
    navigate("/landlord/addpropertykyc");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Property KYC</h1>
        <div className="flex flex-wrap">
          {propertyKycData ? (
            <>
              <a
                href={propertyKycData.business_pin}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full md:w-1/2 lg:w-1/3 p-4 cursor-pointer"
              >
                <div className="bg-white rounded shadow p-4 hover:bg-gray-100">
                  <p className="text-lg font-semibold truncate">Business PIN</p>
                  <p className="truncate">{propertyKycData.business_pin}</p>
                </div>
              </a>
              <a
                href={propertyKycData.reg_certificate}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full md:w-1/2 lg:w-1/3 p-4 cursor-pointer"
              >
                <div className="bg-white rounded shadow p-4 hover:bg-gray-100">
                  <p className="text-lg font-semibold truncate">
                    Registration Certificate
                  </p>
                  <p className="truncate">{propertyKycData.reg_certificate}</p>
                </div>
              </a>
              <a
                href={propertyKycData.cr12_certificate}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full md:w-1/2 lg:w-1/3 p-4 cursor-pointer"
              >
                <div className="bg-white rounded shadow p-4 hover:bg-gray-100">
                  <p className="text-lg font-semibold truncate">
                    CR12 Certificate
                  </p>
                  <p className="truncate">{propertyKycData.cr12_certificate}</p>
                </div>
              </a>
              <a
                href={propertyKycData.utility_bills}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full md:w-1/2 lg:w-1/3 p-4 cursor-pointer"
              >
                <div className="bg-white rounded shadow p-4 hover:bg-gray-100">
                  <p className="text-lg font-semibold truncate">
                    Utility Bills
                  </p>
                  <p className="truncate">{propertyKycData.utility_bills}</p>
                </div>
              </a>
              <a
                href={propertyKycData.business_permit}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full md:w-1/2 lg:w-1/3 p-4 cursor-pointer"
              >
                <div className="bg-white rounded shadow p-4 hover:bg-gray-100">
                  <p className="text-lg font-semibold truncate">
                    Business Permit
                  </p>
                  <p className="truncate">{propertyKycData.business_permit}</p>
                </div>
              </a>
            </>
          ) : (
            <p>Loading property KYC data...</p>
          )}
        </div>
        <button
          onClick={handleAddPropertyKyc}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
        >
          Add Property KYC
        </button>
      </div>
    </AuthGuard>
  );
};

export default PropertyKYCPage;
