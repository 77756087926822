import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";

const CommentsPage = () => {
  const [comments, setComments] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const propertyId = localStorage.getItem("selectedPropertyId");
    const token = localStorage.getItem("token");

    if (propertyId && token) {
      // Fetch comments
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/admin/comments/fetch/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setComments(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching comments:", error);
        });

      // Fetch average ratings
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/admin/comments/average-ratings/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setAverageRating(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching average ratings:", error);
        });
    }
  }, []);

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Property Ratings</h1>
        <p>Average Rating: {averageRating}</p>

        <h2 className="text-3xl font-semibold my-4">Comments</h2>
        {comments.map((comment) => (
          <div
            key={comment.id}
            className="bg-white shadow-md rounded-md p-4 mb-4"
          >
            <p className="text-gray-800">{comment.comments}</p>
          </div>
        ))}

        {/* Add button to navigate back */}
        <button
          onClick={() => navigate(-1)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
        >
          Go Back
        </button>
      </div>
    </AuthGuard>
  );
};

export default CommentsPage;
