import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";

const UpdateRental = () => {
  const [description, setDescription] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleUpdateRental = () => {
    const rentalId = localStorage.getItem("selectedRentalId");
    if (!rentalId || !token) {
      console.error("Rental ID or token not found.");
      return;
    }

    const updatedRentalData = {
      description: description,
      house_number: houseNumber,
    };

    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/admin/rentals/${rentalId}`,
        updatedRentalData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Rental updated successfully!");
        navigate(-1);
        // Optionally, navigate to another page or show a success message
      })
      .catch((error) => {
        toast.error("Error updating rental!");
        console.error("Error updating rental:", error);
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Update Rental</h1>

        <div className="mb-4">
          <label htmlFor="description" className="block text-sm font-semibold">
            Description:
          </label>
          <input
            type="text"
            id="description"
            className="w-full border rounded-md py-2 px-3"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="houseNumber" className="block text-sm font-semibold">
            House Number:
          </label>
          <input
            type="text"
            id="houseNumber"
            className="w-full border rounded-md py-2 px-3"
            value={houseNumber}
            onChange={(e) => setHouseNumber(e.target.value)}
          />
        </div>

        <button
          onClick={handleUpdateRental}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
        >
          Update
        </button>
      </div>
    </AuthGuard>
  );
};

export default UpdateRental;
