import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";

const UpdateAmenity = () => {
  const [amenityName, setAmenityName] = useState("");
  const amenityId = localStorage.getItem("selectedAmenityId");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the amenity details using the amenityId
    if (amenityId && token) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/amenities/fetch/${amenityId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setAmenityName(response.data.amenity_name);
        })
        .catch((error) => {
          console.error("Error fetching amenity details:", error);
        });
    }
  }, [amenityId, token]);

  const handleUpdateAmenity = () => {
    if (!amenityId || !token || !amenityName) {
      console.error("Amenity ID, token, or amenity name not found.");
      return;
    }

    const updatedAmenityData = {
      amenity_name: amenityName,
    };

    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/amenities/update/${amenityId}`,
        updatedAmenityData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Amenity updated successfully!");
        navigate(-1);
      })
      .catch((error) => {
        toast.error("Error updating amenity!");
        console.error("Error updating amenity:", error);
      });
  };

  const handleDeleteAmenity = () => {
    if (!amenityId || !token) {
      console.error("Amenity ID or token not found.");
      return;
    }

    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/amenities/delete/${amenityId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Amenity deleted successfully!");
        navigate(-1);
        // Optionally, redirect to another page or perform any additional actions
      })
      .catch((error) => {
        toast.error("Error deleting amenity!");
        console.error("Error deleting amenity:", error);
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Update Amenity</h1>

        <div className="mb-4">
          <label htmlFor="amenityName" className="block text-sm font-semibold">
            Amenity Name:
          </label>
          <input
            type="text"
            id="amenityName"
            className="w-full border rounded-md py-2 px-3"
            value={amenityName}
            onChange={(e) => setAmenityName(e.target.value)}
          />
        </div>

        <button
          onClick={handleUpdateAmenity}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
        >
          Update
        </button>
        <button
          onClick={handleDeleteAmenity}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          Delete
        </button>
      </div>
    </AuthGuard>
  );
};

export default UpdateAmenity;
