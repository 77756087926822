import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";

const WaterUnitsPage = () => {
  const [waterUnits, setWaterUnits] = useState({
    units: null,
    amount: null,
    water_unit_id: null,
  }); // State for water units as an object
  // State for water units
  const [showAddModal, setShowAddModal] = useState(false); // State for showing add modal
  const [units, setUnits] = useState(null); // State for units
  const [amount, setAmount] = useState(null); // State for amount
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const propertyId = localStorage.getItem("selectedPropertyId");

    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/waterunits/fetchonewaterunit/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const waterUnitsData = response.data.response;

        if (waterUnitsData.length > 0) {
          const { units, amount, water_unit_id } = waterUnitsData[0];
          setWaterUnits({ units, amount, water_unit_id });
        }
      })
      .catch((error) => {
        console.error("Error fetching water units:", error);
      });
  }, []);

  const handleAddWaterUnits = () => {
    // Create FormData object
    const formData = new FormData();
    formData.append("units", units);
    formData.append("amount", amount);

    const propertyId = localStorage.getItem("selectedPropertyId");
    const token = localStorage.getItem("token");

    // Send POST request to add water units
    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/waterunits/postwaterunits/${propertyId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Water units added successfully!");
        setShowAddModal(false); // Close the modal after adding water units
      })
      .catch((error) => {
        toast.error("Error adding water units!");
        console.error("Error adding water units:", error);
      });
  };

  const handleUpdateWaterUnits = () => {
    localStorage.setItem("waterUnits", JSON.stringify(waterUnits));
    navigate("/landlord/updatewaterunits");
  };

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Water Units</h1>

        <div>
          <p>
            If you already have water units just update dont add another one{" "}
          </p>
          <p>Current Water Units: {waterUnits.units}</p>
          <p>Current Water Amount: {waterUnits.amount}</p>

          <button
            onClick={() => setShowAddModal(true)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          >
            Add Water Units
          </button>
          <button
            onClick={handleUpdateWaterUnits}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 ml-2"
          >
            Update Water Units
          </button>
          <button
            onClick={handleBack}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mt-4 ml-2"
          >
            Back
          </button>
        </div>

        {/* Modal for adding water units */}
        <Modal
          isOpen={showAddModal}
          onRequestClose={() => setShowAddModal(false)}
          className="modal"
        >
          <div className="bg-white w-1/3 p-6 rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">Add Water Units</h2>
            <p>
              If you already have water units just update dont add another one{" "}
            </p>
            <div className="mb-4">
              <label htmlFor="units" className="block text-sm font-semibold">
                Units:
              </label>
              <input
                type="number"
                id="units"
                className="w-full border rounded-md py-2 px-3"
                value={units}
                onChange={(e) => setUnits(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="amount" className="block text-sm font-semibold">
                Amount:
              </label>
              <input
                type="number"
                id="amount"
                className="w-full border rounded-md py-2 px-3"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleAddWaterUnits}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Add
              </button>
              <button
                onClick={() => setShowAddModal(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </AuthGuard>
  );
};

export default WaterUnitsPage;
