import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom"; // Import Navigate for redirection
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify"; // Import ToastContainer
import "./style.css"; // Import Tailwind CSS

import Login from "./Auth/Login";
import OtpVerification from "./Auth/OtpVerification";
import Registration from "./Auth/Registration";
import ForgotPassword from "./Auth/ForgotPassword";
import Logout from "./Auth/Logout";
import Home from "./Landlord/home";
import Profile from "./Landlord/profile";
import PropertyPage from "./Landlord/propertypage";
import UpdateProperty from "./Landlord/updateproperty";
import WithdrawalsPage from "./Landlord/withdrawals";
import CommentsPage from "./Landlord/comments";
import ReservationsPage from "./Landlord/reservations";
import AmenitiesPage from "./Landlord/amenities";
import UpdateAmenity from "./Landlord/updateamenity";
import Rentals from "./Landlord/rentals";
import UpdateRental from "./Landlord/updaterental";
import SpecificRental from "./Landlord/specificrental";
import DeletePhoto from "./Landlord/deletephoto";
import DeleteVideo from "./Landlord/deletevideo";
import WaterUnitsPage from "./Landlord/waterunits";
import UpdateWaterUnitsPage from "./Landlord/waterunitsupdate";
import CaretakersPage from "./Landlord/caretaker";
import RegisterCaretaker from "./Landlord/registercaretaker";
import DeleteCaretaker from "./Landlord/deletecaretaker";
import MaintenancePage from "./Landlord/maintenance";
import UpdateMaintenancePage from "./Landlord/updatemaintenance";
import VacatingPage from "./Landlord/vacating";
import UpdateVacatingPage from "./Landlord/updatevacating";
import PropertyKYCPage from "./Landlord/propertykyc";
import AddPropertyKYCPage from "./Landlord/addkyc";
import BillsPage from "./Landlord/bills";
import { useEffect } from "react";
import DepositsPage from "./Landlord/deposits";
import NotificationsPage from "./Landlord/notifications";
import RecordsPage from "./Landlord/recordsview";

function App() {
  return (
    <Router>
      <Routes>
        {/* Routes accessible whether user is logged in or not */}
        <Route path="/" element={<Login />} />
        <Route path="/OtpVerification" element={<OtpVerification />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/logout" element={<Logout />} />
        {/* Routes accessible only when user is logged in */}
        <Route path="/landlord/home" element={<Home />} />
        <Route path="/landlord/profile" element={<Profile />} />
        <Route path="/landlord/propertypage" element={<PropertyPage />} />
        <Route path="/landlord/updateproperty" element={<UpdateProperty />} />
        <Route path="/landlord/withdrawals" element={<WithdrawalsPage />} />
        <Route path="/landlord/deposits" element={<DepositsPage />} />
        <Route path="/landlord/comments" element={<CommentsPage />} />
        <Route path="/landlord/vacating" element={<VacatingPage />} />
        <Route
          path="/landlord/updatevacating"
          element={<UpdateVacatingPage />}
        />
        <Route path="/landlord/caretaker" element={<CaretakersPage />} />
        <Route
          path="/landlord/registercaretaker"
          element={<RegisterCaretaker />}
        />
        <Route path="/landlord/deletecaretaker" element={<DeleteCaretaker />} />
        <Route path="/landlord/maintenance" element={<MaintenancePage />} />
        <Route
          path="/landlord/updatemaintenance"
          element={<UpdateMaintenancePage />}
        />
        <Route path="/landlord/reservations" element={<ReservationsPage />} />
        <Route path="/landlord/propertykyc" element={<PropertyKYCPage />} />
        <Route
          path="/landlord/addpropertykyc"
          element={<AddPropertyKYCPage />}
        />
        <Route path="/landlord/waterunits" element={<WaterUnitsPage />} />
        <Route path="/landlord/amenities" element={<AmenitiesPage />} />
        <Route path="/landlord/rentals" element={<Rentals />} />
        <Route path="/landlord/updateamenity" element={<UpdateAmenity />} />
        <Route path="/landlord/updaterental" element={<UpdateRental />} />
        <Route path="/landlord/specificrental" element={<SpecificRental />} />
        <Route path="/landlord/deletephoto" element={<DeletePhoto />} />
        <Route path="/landlord/deletevideo" element={<DeleteVideo />} />
        <Route
          path="/landlord/updatewaterunits"
          element={<UpdateWaterUnitsPage />}
        />
        <Route path="/landlord/bills" element={<BillsPage />} />
        <Route path="/landlord/notifications" element={<NotificationsPage />} />
        <Route path="/landlord/recordsview" element={<RecordsPage />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;

export function AuthGuard({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    // Retrieve the JSON string from localStorage
    const userDataString = localStorage.getItem("user");

    try {
      // Parse the JSON string to convert it into a JavaScript object
      const userData = JSON.parse(userDataString);
      // Access the 'unique' property from the 'user' object
      const userId = userData.unique;

      if (!userId) {
        navigate("/");
      }
    } catch (error) {
      navigate("/");
    }
  }, []);

  return <>{children}</>;
}

export function TokenGuard({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  return <>{children}</>;
}
